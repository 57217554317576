<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-17 15:41:20
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-10-09 14:26:00
-->
<template>
  <div class="loginPage">
    <header>
      <router-link to="/" class="logobox">
        <img src="@/assets/login_logo.png" class="logo" />
      </router-link>
    </header>
    <!-- <grejob-header /> -->
    <article :style="'background-image:url(' + bgImg.pic + ')'">
      <div class="center">
        <div class="login_box">
          <el-tabs v-model="activeName" stretch>
            <el-tab-pane label="密码登录" name="password">
              <passwordLogin @activeName="activeName = $event" />
            </el-tab-pane>
            <el-tab-pane label="验证码登录" name="sms_code">
              <smsCodeLogin v-if="activeName == 'sms_code'" />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </article>
    <footer>
      <div class="contracts">
        <div class="item">咨询热线：400-880-1190</div>
        <div class="item">
          咨询热线：
          <a href="javascript:void(0);" @click="easemobim.bind()"
            >（点击咨询）</a
          >
        </div>
        <div class="item">微信咨询：13006623709</div>
        <div class="item">公司地址：深圳市南山区科技园南区数字技术园B1栋6A</div>
      </div>
      <p>
        <span>©Copyright 2019. 工蚁宝版权所有</span>
        <span>|</span>
        <a href="http://www.beian.miit.gov.cn" target="_blank"
          >粤ICP备19110558号-1</a
        >
      </p>
    </footer>
  </div>
</template>

<script>
//import grejobHeader from "@/components/header/index.vue";
import passwordLogin from "./components/passwordLogin.vue";
import smsCodeLogin from "./components/smsCodeLogin.vue";
import api from "@/api/list";
export default {
  name: "login",
  components: {
    passwordLogin,
    smsCodeLogin,
    //grejobHeader,
  },
  data() {
    return {
      activeName: "password",
      bgImg: {
        pic: require("../../assets/login_bg.png"),
      },
    };
  },
  mounted() {
    this.easemobim = window.easemobim || {};
    this.easemobim.config = {
      hide: true,
      //autoConnect: true,
      configId: "aaab0cb4-4a17-478b-9ab7-e90e515fbb15",
      dialogPosition: { x: "100px", y: "10px" },
    };

    api.getNoLoginAD({}, { subUrl: "/PCLoginBanner" }).then((res) => {
      if (res && res.data && res.data.length) {
        this.bgImg = res.data[0];
        this.bgImg.pic = process.env.VUE_APP_FILE_PATH + res.data[0].pic;
      }
    });
  },
};
</script>

<style lang="less" scoped>
.loginPage {
  position: relative;
  background: #fff;
  min-height: 100vh;
  padding-bottom: 200px;
  margin-bottom: -340px;
  header {
    width: 1210px;
    margin: 0 auto;
    padding: 30px 0;
  }
}

article {
  padding: 22px 0;
  //margin-top: 100px;
  //background-image: url(../../assets/login_bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  .center {
    width: 1210px;
    margin: 0 auto;
    overflow: hidden;
  }
  .login_box {
    width: 342px;
    float: right;
    background: #fff;
    border-radius: 10px;
    padding: 20px 30px 0;
  }

  /deep/.el-tabs__content {
    padding-top: 20px;
  }

  /deep/ .el-tabs__item {
    color: #666;

    & + .el-tabs__item {
      &.is-active {
        &:before {
          margin-left: -15px;
        }
      }
    }

    &.is-active {
      position: relative;
      font-size: 18px;
      color: #333;
      line-height: 30px;
      &:before {
        content: " ";
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 50px;
        height: 4px;
        background: #fadd45;
        border-radius: 4px;
        margin-left: -35px;
      }
    }
  }

  /deep/ .el-tabs__nav-wrap::after,
  /deep/.el-tabs__active-bar {
    background-color: transparent;
  }
}

footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #666;
  text-align: center;
  line-height: 20px;
  padding-bottom: 50px;
  margin: 0 auto;
  a {
    color: #666;
  }
  .contracts {
    width: 1210px;
    margin: 0 auto 20px;
    .item {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      margin: 0 10px;
    }
  }

  p {
    font-size: 12px;
  }
}
</style>
