<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-25 10:52:42
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-10-09 14:20:52
-->
<template>
  <el-form :model="loginForm" :rules="loginFormRules" ref="loginForm" class="loginForm">
    <el-form-item prop="username">
      <el-input 
        v-model="loginForm.username"
        placeholder="请输入手机号"
        @keyup.enter.native="submitForm('loginForm')"
      ></el-input>
    </el-form-item>
    <el-form-item prop="code">
      <el-input
        v-model="loginForm.code"
        placeholder="请输入验证码"
        @keyup.enter.native="submitForm('loginForm')"
      >
        <el-button slot="append" class="graphic-code" @click="getLoginCode">
          <img v-if="codeImg != ''" :src="codeImg" alt="验证码" class="imgCode" />
          <span v-else class="imgCode">点击重新加载</span>
        </el-button>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        v-model="loginForm.password"
        placeholder="请输入动态码"
        @keyup.enter.native="submitForm('loginForm')"
      >
        <template slot="append">
          <el-link type="info" v-if="isOn">({{count}}秒)后重新发送</el-link>
          <el-link type="primary" @click="handleCode" v-else>发送验证码</el-link>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item class="buttons">
      <el-button
        type="primary"
        @click="submitForm('loginForm')"
        :loading="loginLoading"
      >{{loginText}}</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import api from "@/api/list";
import { apiList } from "@/api/list";
export default {
  data() {
    return {
      loginLoading: false,
      loginText: "登录",
      count: 60,
      codeImg: "",
      isOn: false,
      loginForm: {
        username: "",
        password: "",
        grant_type: "sms_code",
        token: "",
        imgToken: "",
        code: "",
      },
      loginFormRules: {
        username: [
          { required: true, message: "请输入您的手机号", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "手机号应该为11位数字",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入动态码", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入验证码!", trigger: "blur" },
          { validator: this.validateInputCode, trigger: "blur" },
        ],
      },
    };
  },
  mounted: function () {
    this.getLoginCode();
  },
  watch: {
    codeImg: function () {},
  },
  methods: {
    getLoginCode() {
      this.loginForm.imgToken = this.getRandomCode(32);
      api.getImgCode({ token: this.loginForm.imgToken }).then(
        (response) => {
          this.codeImg =
            process.env.VUE_APP_BASE_API +
            apiList.getImgCode +
            "?token=" +
            this.loginForm.imgToken;
        },
        (error) => {
          this.codeImg = "";
        }
      );
    },
    getRandomCode(length) {
      if (length > 0) {
        var data = [
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
          "a",
          "b",
          "c",
          "d",
          "e",
          "f",
          "g",
          "h",
          "i",
          "j",
          "k",
          "l",
          "m",
          "n",
          "o",
          "p",
          "q",
          "r",
          "s",
          "t",
          "u",
          "v",
          "w",
          "x",
          "y",
          "z",
        ];
        var nums = "";
        for (var i = 0; i < length; i++) {
          var r = parseInt(Math.random() * 61);
          nums += data[r];
        }
        return nums;
      } else {
        return false;
      }
    },
    validateInputCode(rule, value, callback) {
      api
        .validateCode({ token: this.loginForm.imgToken, code: value })
        .then((response) => {
          if (!value || response.data) {
            callback();
          } else {
            callback("您输入的验证码不正确!");
          }
        });
    },
    handleCode() {
      if (this.loginForm.username == "") {
        this.$message({
          showClose: true,
          message: "请输入手机号码",
          type: "warning",
        });
        return false;
      }

      if (this.loginForm.username.length !== 11) {
        this.$message({
          showClose: true,
          message: "请输入正确的手机号码",
          type: "warning",
        });
        return false;
      }

      api.sendSmsCode({ mobile: this.loginForm.username }).then((res) => {
        this.loginForm.token = res.data;
        this.isOn = true;
        let that = this;
        let interval = window.setInterval(function () {
          --that.count;
          if (that.count < 0) {
            that.isOn = false;
            that.count = 60;
            window.clearInterval(interval);
          }
        }, 1000);
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loginLoading = true;
          this.loginText = "登录中";
          let temp = JSON.parse(JSON.stringify(this.loginForm));
          delete temp.code;
          delete temp.imgToken;

          this.$store
            .dispatch("Login", temp)
            .then((res) => {
              this.$router.push({
                path:
                  typeof this.$store.getters.enterRoute == "object"
                    ? this.$store.getters.enterRoute.fullPath
                    : "/",
              });
              this.loginLoading = false;
              this.loginText = "登录";
            })
            .catch(() => {
              this.loginLoading = false;
              this.loginText = "登录";
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="less" scoped>
.loginForm {
  /deep/ .el-link--info {
    .el-link--inner {
      color: #999;
    }
  }
  /deep/ .el-input__inner {
    background: #f3f3f3;
    border-color: #f3f3f3;
  }

  /deep/ .el-input-group__append {
    background: #f3f3f3;
    border: none;
  }

  .graphic-code {
    position: relative;
    top: 7px;
    padding: 0;
  }

  .imgCode {
    display: block;
    height: 38px;
    line-height: 38px;
    min-width: 120px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #999;
  }
}
.buttons {
  /deep/ .el-button {
    display: block;
    width: 100%;
    margin: 42px 0 12px;

    &.el-button--warning {
      background: #ffbf25;
      border-color: #ffbf25;
    }

    &.el-button--primary {
      background: #5f6eea;
      border-color: #5f6eea;
    }
  }
}

/deep/ input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f3f3f3 inset;
}
</style>