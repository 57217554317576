<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-25 10:52:38
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-23 11:39:40
-->
<template>
  <el-form :model="loginForm" :rules="loginFormRules" ref="loginForm" class="loginForm">
    <el-form-item prop="username">
      <el-input
        v-model="loginForm.username"
        placeholder="请输入手机号"
        ref="autofocusInput"
        @keyup.enter.native="submitForm('loginForm')"
      ></el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        type="password"
        v-model="loginForm.password"
        placeholder="请输入密码"
        @keyup.enter.native="submitForm('loginForm')"
      ></el-input>
    </el-form-item>
    <el-form-item class="links">
      <router-link to="/register">注册</router-link>
      <router-link to="/resetPassword">忘记密码？</router-link>
    </el-form-item>
    <el-form-item class="buttons">
      <el-button
        type="warning"
        @click="submitForm('loginForm')"
        :loading="loginLoading"
      >{{loginText}}</el-button>
      <el-button type="primary" @click="handleFirstTime">首次登录</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { rsaEncrypt } from "@/utils/rsa.js";
export default {
  data() {
    return {
      loginLoading: false,
      loginText: "登录",
      loginForm: {
        username: "",
        password: "",
        grant_type: "password",
      },
      loginFormRules: {
        username: [
          { required: true, message: "请输入您的账号", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "手机号应该为11位数字",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  created() {
    this.$nextTick(() => {
      this.$refs.autofocusInput.focus();
    });
  },
  methods: {
    handleFirstTime() {
      this.$emit("activeName", "sms_code");
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loginLoading = true;
          this.loginText = "登录中";
          let temp = JSON.parse(JSON.stringify(this.loginForm));
          // 加密数据
          temp.password = encodeURIComponent(rsaEncrypt(temp.password));

          this.$store
            .dispatch("Login", temp)
            .then((res) => {
                if (res.status == "200") {
                    this.$router.push({
                        path:
                            typeof this.$store.getters.enterRoute == "object"
                                ? this.$store.getters.enterRoute.fullPath
                                : "/",
                    });
                }else {
                    console.log("密码错误，请5分钟后再试！");
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning',
                        duration: 5 * 1000
                    });
                }
              this.loginLoading = false;
              this.loginText = "登录";
            })
            .catch(() => {
              this.loginLoading = false;
              this.loginText = "登录";
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
.loginForm {
  /deep/ .el-input__inner {
    background: #f3f3f3;
    border-color: #f3f3f3;
  }
}
.buttons {
  /deep/ .el-button {
    display: block;
    width: 100%;
    margin: 12px 0;

    &.el-button--warning {
      background: #ffbf25;
      border-color: #ffbf25;
    }

    &.el-button--primary {
      background: #5f6eea;
      border-color: #5f6eea;
    }
  }
}

.is-error + .links {
  margin-top: -10px;
  margin-bottom: 10px;
}

.links {
  margin-top: -22px;
  overflow: hidden;
  a {
    float: left;
    & + a {
      float: right;
    }
  }
}

/deep/ input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f3f3f3 inset;
}
</style>